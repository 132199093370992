import { useEffect, useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import NavBar from "../components/NavBar";
import { UserDetailsApi } from "../services/Api";
import { logout, isAuthenticated } from "../services/Auth";
import FileProtectionTool from "../components/FileProtectionTool";
import './dashboard.css';
 // Import the FileProtectionTool component

export default function DashboardPage() {
    const navigate = useNavigate();
    const [user, setUser] = useState({ name: "", email: "", localId: "" });

    useEffect(() => {
        if (isAuthenticated()) {
            UserDetailsApi().then((response) => {
                setUser({
                    name: response.data.users[0].displayName,
                    email: response.data.users[0].email,
                    localId: response.data.users[0].localId,
                });
            });
        }
    }, []);

    const logoutUser = () => {
        logout();
        navigate("/login");
    };

    if (!isAuthenticated()) {
        // Redirect user to login if not authenticated
        return <Navigate to="/login" />;
    }

    return (
        <div>
            <NavBar logoutUser={logoutUser} />
            <main role="main" className="container mt-5">
                <div className="container text-center mt-5">
                 
                    {user.name && user.email && user.localId ? (
                        <div className="user-details">
                        <h1 className="mb-4"><strong>Hi {user.name}</strong></h1>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Field</th>
                                    <th>Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Firebase ID</td>
                                    <td>{user.localId}</td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td>{user.email}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    ) : (
                        <p>Loading...</p>
                    )}

                    {/* Render FileProtectionTool if the user is authenticated */}
                
                </div>
              
            </main>
            <FileProtectionTool />
        </div>
    );
}
